import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const MarkdownPageTemplate = ({ data, location, pageContext }) => {
    const page = data.markdown;
    const siteTitle = data.site.siteMetadata.title;
    const banner = data.banner;

    return (
        <Layout location={location} title={siteTitle} banner={banner?.childImageSharp}>
            <SEO title={page.frontmatter.title} language={pageContext.language}/>
            <article>
                <section dangerouslySetInnerHTML={{ __html: page.html }} />
            </article>
        </Layout>
    )
}

export default MarkdownPageTemplate

export const pageQuery = graphql`
    query PageByPath($path: String!, $banner: String!) {
        site {
            siteMetadata {
                title
            }
        }
        markdown: markdownRemark(frontmatter: { path: { eq: $path } }) {
            id
            html
            frontmatter {
                title
                path
            }
        }
        banner: file(relativePath: { eq: $banner }) {
            childImageSharp {
                fluid(maxWidth: 3000, quality: 90, srcSetBreakpoints: [200, 768, 1000, 2000, 3000]) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
